// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const UDUSER = 'UDUSER';
export const UDTOKEN = 'UDTOKEN';
export const CHCHANGE = 'CHCHANGE';
export const CHGRANT = 'CHGRANT';
export const ENDAT = 'ENDAT';
export const STARTAT = 'STARTAT';
export const UDPOS = 'UDPOS';
export const UDDISPLANG = 'UDDISPLANG';
export const UDLANGLIST = 'UDLANGLIST';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
