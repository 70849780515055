import React, { useEffect, useState, lazy } from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Modal,
    Stack,
    Paper,
    Typography,
    TextField,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    OutlinedInput
} from '@mui/material';

import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
// assets
import { IconReplace, IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons-react';
import User1 from 'assets/images/users/user-round.svg';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Loading from 'utils/Loading';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    boxShadow: 24
};

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const ChannelChange = ({ chId, textRef, setOpen, chData, adminList, setChannelChangeModal, setChData }) => {
    const { handleChannelChange } = useAuth();
    const { borderRadius } = useConfig();
    const [isLoading, setIsLoading] = useState(true);
    const [chData1, setChData1] = useState(chData);
    const [data, setData] = useState();
    const [alertDialog, setAlertDialog] = useState(false);
    const [successflg, setSuccessflg] = useState(false);
    const [changeId, setChangeId] = useState(chId);
    const [access, setAccess] = useState();

    let text = null;
    if (successflg === false) text = `Change Channel:${chData1.find((el) => changeId === el.id)?.name}?`;
    else if (successflg === true) text = 'Changed';

    const dataSet = (id) => {
        setChangeId(id);
        setAccess(chData1.find((el) => id === el.id)?.access);
        setData(chData1.find((el) => id === el.id));
    };
    const handleChannelModalClose = () => {
        setChData([]);
        setSuccessflg(false);
        setAlertDialog(false);
        setChannelChangeModal(false);
    };

    const handleChannelModalClose1 = () => {
        setChData([]);
        setSuccessflg(false);
        setAlertDialog(false);
        setChannelChangeModal(false);
        setOpen(false);
    };

    const handleAlertDialogOpen = () => {
        setAlertDialog(true);
    };

    const handleAlertDialogClose = () => {
        setSuccessflg(false);
        setAlertDialog(false);
    };

    const changeChannelId = (id, access, data) => {
        handleChannelChange(id, access, data);
        setSuccessflg(true);
    };

    const theme = useTheme();

    console.log('chData', chData);
    useEffect(() => {
        setTimeout(() => {
            setChData1(chData);
            setIsLoading(false);
        }, 2000);
    }, [chData]);
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} padding={2}>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <FormControl fullWidth>
                            <InputLabel id="demo-dialog-select-label">channelId</InputLabel>
                            <Select
                                labelId="demo-dialog-select-label"
                                select="true"
                                fullWidth
                                label="channelId"
                                value={changeId}
                                onChange={(e) => {
                                    dataSet(e.target.value);
                                }}
                                ref={textRef}
                                input={<OutlinedInput label="channelId" />}
                            >
                                {chData1.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Grid>

                <Grid container marginTop={3} marginBottom={1} spacing={3} justifyContent="center">
                    <Grid item>
                        <AnimateButton>
                            <Button variant="contained" color="inherit" onClick={handleChannelModalClose} sx={{ width: 200 }}>
                                close
                            </Button>
                        </AnimateButton>
                    </Grid>

                    <Grid item>
                        <AnimateButton>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleAlertDialogOpen(e, chId)}
                                sx={{ width: 200 }}
                                disabled={access === undefined}
                            >
                                change
                            </Button>
                        </AnimateButton>
                    </Grid>
                    <Dialog
                        open={alertDialog}
                        onClose={handleAlertDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{ p: 3 }}
                        maxWidth="xs"
                        fullWidth
                    >
                        {alertDialog && (
                            <>
                                <DialogTitle id="alert-dialog-title">Change Channel</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Grid>
                                            <Grid>
                                                <Typography sx={{ fontSize: '1.2rem' }} component="span">
                                                    {text}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ pr: 2.5 }}>
                                    {successflg === true ? (
                                        <Button
                                            sx={{
                                                color: theme.palette.error.dark,
                                                borderColor: theme.palette.error.dark,
                                                fontSize: '1.2rem'
                                            }}
                                            onClick={handleChannelModalClose1}
                                            color="secondary"
                                            variant="outlined"
                                        >
                                            close
                                        </Button>
                                    ) : (
                                        <Button
                                            sx={{
                                                color: theme.palette.error.dark,
                                                borderColor: theme.palette.error.dark,
                                                fontSize: '1.2rem'
                                            }}
                                            onClick={handleAlertDialogClose}
                                            color="secondary"
                                            variant="outlined"
                                        >
                                            cancel
                                        </Button>
                                    )}
                                    {successflg === true ? (
                                        ''
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                changeChannelId(changeId, access, data);
                                            }}
                                            autoFocus
                                            sx={{ fontSize: '1.2rem' }}
                                        >
                                            change
                                        </Button>
                                    )}
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                </Grid>
            </Grid>
        </>
    );
};

export default ChannelChange;
