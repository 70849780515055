import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import axios from 'axios';
import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import jwtDecode from 'jwt-decode';
// project imports
import Loader from 'ui-component/Loader';

import accountReducer from 'store/accountReducer';
import { CHCHANGE, CHGRANT, ENDAT, LOGIN, LOGOUT, STARTAT, UDDISPLANG, UDLANGLIST, UDPOS, UDTOKEN, UDUSER } from 'store/actions';

// firebase package imports
import { getAuth, getRedirectResult, updateProfile } from 'firebase/auth';
// cookie
import { useCookies } from 'react-cookie';

const today = new Date();

const date = new Date();
const monthAgo = date.setDate(date.getDate() - 30);

const userInfo = {
    userId: '',
    token: '',
    language: '',
    userName: '',
    points: '',
    mediaPath: '',
    firebaseUid: '',
    lineUserId: '',
    status: '',
    attributes: {}
};

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    });
}

const auth = getAuth();

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    tokens: null,
    chId: null,
    admins: null,
    funcItems: null,
    langList: null,
    groupItems: null,
    dispLangList: null,
    startAt: null,
    endAt: null,
    lat: null,
    lng: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// getRedirectResult格納用
let displayName = '';
let photoURL = '';
let providerId = '';
let uid = '';
let cChId = '';

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);
let defaultId;
let admin;
let func;
let userData;
let langList = [];
// let dispLangList = []
let dispLangList = {};
let groupItems = [];
let lat;
let lng;

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [cookies, setCookie, removeCookie] = useCookies();

    cChId = String(cookies.chId);

    const fbHash = window.location.hash;
    console.log('hash', fbHash);

    useEffect(() => {
        (async () => {
            firebase.auth().onAuthStateChanged((user) => {
                console.log('onAuthStateChanged user', user);

                if (user) {
                    getRedirectResult(auth)
                        .then(async (result) => {
                            if (result) {
                                // SSOでリダイレクトされてきたユーザ情報の取得
                                console.log('getRedirectResult', result);
                                console.log('認証済みauthの情報', auth);
                                displayName = result.user.providerData[0].displayName;
                                photoURL = result.user.providerData[0].photoURL;
                                providerId = result.user.providerData[0].providerId;
                                uid = result.user.providerData[0].uid;
                                // User is signed in.
                                // IdP data available in result.additionalUserInfo.profile.
                            } else {
                                displayName = user.multiFactor.user.providerData[0].displayName;
                                photoURL = user.multiFactor.user.providerData[0].photoURL;
                                providerId = user.multiFactor.user.providerData[0].providerId;
                                uid = user.multiFactor.user.providerData[0].uid;
                            }

                            console.log('OAuthにて認証したUID', uid);

                            // start ユーザ情報取得〜チャンネルインフォ取得まで
                            const getUser = {
                                admin_userid: user.uid
                            };
                            const headers = {
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '',
                                Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
                            };

                            await axios
                                .post(`${process.env.REACT_APP_FINDOUT_API_URL}admin1/adminUser/item`, getUser, {
                                    headers
                                })
                                .then(async (res) => {
                                    console.log('success ユーザ情報取得結果', res.data);
                                    if (res.data.itemData !== undefined) {
                                        // 既存ユーザドキュメント有り、チャンネルインフォ取得
                                        userData = res.data.itemData;
                                        /* console.log('ua', Object.keys(res.data.itemData[0].data.admins)); */
                                        const groupId = res.data.itemData.provider_group;
                                        admin = res.data.itemData.access;
                                        console.log(groupId);
                                        const id = {
                                            provider_group: groupId,
                                            ascdesc: 'desc'
                                        };
                                        axios
                                            .post(`${process.env.REACT_APP_FINDOUT_API_URL}actions3/channel/items`, id, {
                                                headers
                                            })
                                            .then((res) => {
                                                /* console.log('success', res.data); */
                                                groupItems.push(...res.data.itemData);
                                            });
                                        lat = 0;
                                        lng = 0;
                                        // ユーザ作成処理
                                        // } else {
                                        //     // ユーザ作成処理
                                        //     // コンテキスト返却値: ユーザ言語設定
                                        //     // userInfo.language = language; // TODO:言語取得方法は用確認
                                        //     let userProfile = {};
                                        //     const userAddUrl = `${process.env.REACT_APP_FINDOUT_API_URL}actionsgen23/userAdd`;
                                        //     // 新規ユーザドキュメントの作成
                                        //     userProfile = {
                                        //         name: displayName,
                                        //         status: 1,
                                        //         language: 'ja',
                                        //         pictureUrl: photoURL,
                                        //         channelId: '1657842111', // TODO: findoutの公式チャンネル固定値
                                        //         uuid: user.uid,
                                        //         lineUserId: uid,
                                        //         // 任意項目
                                        //         attributes: {
                                        //             providerId
                                        //         }
                                        //     };
                                        //     const userProfileJson = JSON.stringify(userProfile);
                                        //     try {
                                        //         await axios
                                        //             .post(userAddUrl, userProfileJson, {
                                        //                 headers
                                        //             })
                                        //             .then((useRes) => {
                                        //                 console.log('ユーザ作成・更新成功');
                                        //                 const userResultJson = JSON.parse(JSON.stringify(useRes.data));
                                        //                 console.log('ユーザ作成・更新後レスポンス', userResultJson);
                                        //                 userInfo.token = user.auth.currentUser.accessToken;
                                        //                 userInfo.userId = userResultJson.itemId;
                                        //                 userInfo.userName = displayName;
                                        //                 userInfo.birthDay = userProfile.birthDay;
                                        //                 userInfo.firebaseUid = userProfile.firebaseUid;
                                        //                 userInfo.lineUserId = userProfile.lineUserId;
                                        //                 // userInfo.language = userProfile.language;
                                        //                 userInfo.language = 'ja';
                                        //                 userInfo.points = userProfile.points;
                                        //                 userInfo.attributes = userProfile.attributes;
                                        //                 // FirebaseAuthユーザプロフィールの更新
                                        //                 const updateResult = updateProfile(auth.currentUser, {
                                        //                     displayName: userInfo.userId
                                        //                 });
                                        //                 console.log('updateProfileResult', updateResult);
                                        //             })
                                        //             .then(() => {
                                        //                 const res = axios.post(
                                        //                     `${process.env.REACT_APP_FINDOUT_API_URL}actionsgen23/userInfo`,
                                        //                     getUser,
                                        //                     {
                                        //                         headers
                                        //                     }
                                        //                 );
                                        //                 return new Promise((resolve, reject) => {
                                        //                     resolve(res);
                                        //                 });
                                        //             })
                                        //             .then((res) => {
                                        //                 console.log('success ドキュメント作成後ユーザ情報取得結果', res?.data);
                                        //                 // 既存ユーザドキュメント有り、チャンネルインフォ取得
                                        //                 userData.push(res?.data?.itemData[0]);
                                        //                 const chList = res?.data?.itemData[0]?.data?.channelId;

                                        //                 chList.forEach((item) => {
                                        //                     const data = {
                                        //                         channelId: item,
                                        //                         uuid: user?.uid
                                        //                     };
                                        //                     axios
                                        //                         .post(
                                        //                             `${process.env.REACT_APP_FINDOUT_API_URL}actionsgen23/channelInfo`,
                                        //                             data,
                                        //                             {
                                        //                                 headers
                                        //                             }
                                        //                         )
                                        //                         .then((res) => {
                                        //                             console.log('success', res?.data);
                                        //                             chData.push(Object.assign(res?.data?.itemData, { id: item }));
                                        //                         });
                                        //                 });
                                        //             });
                                        //     } catch (e) {
                                        //         console.log('ユーザ作成、更新処理にてエラー発生', e);
                                        //         if (e.statusCode === 500) {
                                        //             // findoutのユーザ作成処理に失敗
                                        //             console.log('eCode500: Server Error');
                                        //         }
                                        //     }
                                        dispatch({
                                            type: LOGIN,
                                            payload: {
                                                isLoggedIn: true,
                                                user: {
                                                    uid: user.uid,
                                                    email: user?.email,
                                                    userData
                                                },
                                                tokens: {
                                                    token: user.auth.currentUser.accessToken,
                                                    key: process.env.REACT_APP_FIREBASE_API_KEY,
                                                    refreshToken: user.refreshToken,
                                                    jwt: process.env.REACT_APP_JWT_TOKEN
                                                },
                                                fbHash,
                                                admins: admin,
                                                langList,
                                                dispLangList,
                                                groupItems,
                                                lat,
                                                lng,
                                                startAt: dayjs(monthAgo).format('YYYY/MM/DD HH:mm:ss'),
                                                endAt: dayjs(today).format('YYYY/MM/DD HH:mm:ss')
                                            }
                                        });
                                    } else {
                                        // アカウントが存在しないパターン
                                        dispatch({
                                            type: LOGOUT
                                        });
                                    }
                                });
                        })
                        .catch((error) => {
                            // Handle error.
                            console.log('getRedirect error', error);
                            dispatch({
                                type: LOGOUT
                            });
                        });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = () => {
        firebase.auth().signOut();
        displayName = '';
        photoURL = '';
        providerId = '';
        uid = '';
        cChId = '';
        defaultId = '';
        admin = '';
        func = '';
        userData = '';
        langList = [];
        dispLangList = {};
        groupItems = [];
        dispatch({
            type: LOGOUT
        });
    };

    const handleChannelChange = (chengedChannelId, access, data) => {
        dispatch({
            type: CHCHANGE,
            payload: {
                isLoggedIn: state.isLoggedIn,
                user: {
                    uid: state.user.uid,
                    email: state.user.email,
                    userData: state.user.userData,
                    chData: [data]
                },

                chId: chengedChannelId,
                admins: access
            }
        });
        setCookie('chId', String(chengedChannelId));
        cChId = String(chengedChannelId);
    };

    const handleGrantChange = (admin) => {
        dispatch({
            type: CHGRANT,
            payload: {
                admins: admin
            }
        });
    };
    const handleTokenUpdate = (newToken, newRefreshToken) => {
        console.log('TokenUpdated');
        dispatch({
            type: UDTOKEN,
            payload: {
                tokens: {
                    token: newToken,
                    key: state.tokens.key,
                    refreshToken: newRefreshToken,
                    jwt: process.env.REACT_APP_JWT_TOKEN
                }
            }
        });
    };

    const handleUserUpdate = (user) => {
        console.log('UserUpdated');
        dispatch({
            type: UDUSER,
            payload: {
                user: {
                    uid: user.uid,
                    email: user.email,
                    userData: user.userData,
                    chData: user.chData
                }
            }
        });
    };
    const handleStartAtChange = (start) => {
        dispatch({
            type: STARTAT,
            payload: {
                startAt: start
            }
        });
    };

    const handleEndAtChange = (end) => {
        dispatch({
            type: ENDAT,
            payload: {
                endAt: end
            }
        });
    };
    const handlePosChange = (lat, lng) => {
        dispatch({
            type: UDPOS,
            payload: {
                lat,
                lng
            }
        });
    };

    const handleDispLangChange = (dispLangList) => {
        console.log('getDispLang');
        dispatch({
            type: UDDISPLANG,
            payload: {
                dispLangList
            }
        });
    };

    const handleLangListChange = (langList) => {
        console.log('getLangList');
        dispatch({
            type: UDLANGLIST,
            payload: {
                langList
            }
        });
    };

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    // const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                handleChannelChange,
                handleGrantChange,
                handleStartAtChange,
                handleEndAtChange,
                handleTokenUpdate,
                handleUserUpdate,
                handlePosChange,
                handleDispLangChange,
                handleLangListChange
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
