import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const Group = Loadable(lazy(() => import('views/application/Group')));
const Dashboard = Loadable(lazy(() => import('views/application/Dashboard')));
const ProviderUser = Loadable(lazy(() => import('views/application/ProviderUser')));
const FindoutUser = Loadable(lazy(() => import('views/application/findoutUser')));
const Contents = Loadable(lazy(() => import('views/application/Contents')));
const Beacon = Loadable(lazy(() => import('views/application/Beacon')));
const IgComments = Loadable(lazy(() => import('views/application/IgComments')));
const MediaManager = Loadable(lazy(() => import('views/application/MediaManager')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/group',
            element: <Group />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/provideruser',
            element: <ProviderUser />
        },
        {
            path: '/findoutuser',
            element: <FindoutUser />
        },
        {
            path: '/contents',
            element: <Contents />
        },
        {
            path: '/beacon',
            element: <Beacon />
        },
        {
            path: '/comments',
            element: <IgComments />
        },
        {
            path: '/mediamanager',
            element: <MediaManager />
        }

        /* {
            path: '/pdfviewer',
            element: <PdfViewer />
        },
        {
            path: '/task',
            element: <Task />
        },
        {
            path: '/message',
            element: <Message />
        },
        {
            path: '/findoutuser',
            element: <FindoutUser />
        },
        {
            path: '/subscription',
            element: <Subscription />
        },
        {
            path: '/survey',
            element: <Survey />
        },
        {
            path: '/prize',
            element: <Prize />
        },
        {
            path: '/admin',
            element: <Admin />
        },
        {
            path: '/channel',
            element: <Channel />
        },
        {
            path: '/keyword',
            element: <Keyword />
        },
        {
            path: '/searchtype',
            element: <SearchType />
        },
        {
            path: '/sensor',
            element: <Sensor />
        },
        {
            path: '/schedule',
            element: <Schedule />
        },
        {
            path: '/management',
            element: <Management />
        } */
    ]
};

export default MainRoutes;
