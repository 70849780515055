import { useEffect, useRef, useState, useReducer } from 'react';
import { redirect, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography,
    FormControl,
    MenuItem,
    TextField,
    InputLabel,
    Select,
    Button
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import useAuth from 'hooks/useAuth';
import User1 from 'assets/images/users/user-round.svg';
import FormControlSelect from 'ui-component/extended/Form/FormControlSelect';
import ChannelChangeModal from './ChannelChangeModal';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser, IconReplace, IconSwitch3, IconTool } from '@tabler/icons-react';
import useConfig from 'hooks/useConfig';
import { replace } from 'lodash';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const { user, logout } = useAuth();
    const theme = useTheme();

    const { borderRadius } = useConfig();
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [changeId, setChangeId] = useState('');
    const [open, setOpen] = useState(false);

    // const chList = user === null ? [] : Object.keys(user?.userData[0].data.admins);
    const anchorRef = useRef(null);
    const textRef = useRef(null);
    const handleLogout = async () => {
        try {
            await logout();
            navigate('/', { replace: true });
        } catch (err) {
            console.error(err);
        }
    };
    console.log('user', user);
    const handleManegement = () => {
        navigate('/management', { replace: true });
    };

    // const manage = user?.userData[0].data.management;
    const handleClose = (event) => {
        const anchor = anchorRef.current && anchorRef.current?.contains(event.target);
        const text = textRef.current && textRef.current?.contains(event.target);
        /* if (anchor === true && text === undefined) {
            console.log('1');
        } else if (anchor === false && text === undefined) {
            console.log('2');
        } else if (anchor === undefined && text === undefined) {
            console.log('3');
        } else if (anchor === true && text === false) {
            console.log('4');
        } else if (anchor === false && text === false) {
            console.log('5');
            setOpen(true);
        } else if (anchor === undefined && text === false) {
            console.log('6');
        } else if (anchor === true && text === true) {
            console.log('7');
        } else if (anchor === false && text === true) {
            console.log('8');
        } else if (anchor === undefined && text === true) {
            console.log('9');
        } else if (anchor === true || text === undefined) {
            console.log('10');
            setOpen(false);
        } else if (anchor === false || text === undefined) {
            console.log('11');
            setOpen(false);
        } else if (anchor === undefined || text === undefined) {
            console.log('12');
        } else if (anchor === true || text === false) {
            console.log('13');
        } else if (anchor === true || text === true) {
            console.log('14');
        } else {
            console.log('15');
        } */
        if (anchor === false && text === false) {
            setOpen(true);
        } else if (anchor === true || text === undefined) {
            setOpen(false);
        } else if (anchor === false || text === undefined) {
            setOpen(false);
        }
    };

    const handleClose1 = () => {
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);
        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleChange = (event) => {
        console.log(event);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                        alt="user-account"
                        src={user?.userData.admin_pic}
                    />
                }
                label={<IconSettings stroke={1.5} size="24px" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={(e) => handleToggle(e)}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack direction="row" spacing={0.5} alignItems="center" sx={{ marginLeft: 1 }}>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    Administrator
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        <PerfectScrollbar
                                            style={{
                                                height: '100%',
                                                maxHeight: 'calc(100vh - 20vh)',
                                                overflowX: 'hidden'
                                            }}
                                        >
                                            <Box sx={{ p: 2, pt: 0 }}>
                                                <Divider sx={{ marginTop: 1 }} />
                                                <Divider />
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 350,
                                                        minWidth: 300,
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '10px',
                                                        [theme.breakpoints.down('md')]: {
                                                            minWidth: '100%'
                                                        },
                                                        '& .MuiListItemButton-root': {
                                                            mt: 0.5
                                                        }
                                                    }}
                                                >
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Avatar src={user?.userData.admin_pic} sx={{ width: 30, height: 30 }} />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2" sx={{ fontSize: 20 }}>
                                                                    {user?.userData.admin_name}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                    {/* {chList?.length > 1 ? (
                                                        <ChannelChangeModal user={user} chId={chId} textRef={textRef} setOpen={setOpen} />
                                                    ) : (
                                                        ''
                                                    )}
                                                    {chList?.length > 1 ? <Divider sx={{ marginTop: 2 }} /> : ''} */}
                                                    {/* {manage === 1 ? (
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            selected={selectedIndex === 4}
                                                            onClick={handleManegement}
                                                        >
                                                            <ListItemIcon>
                                                                <IconTool stroke={1.5} size="30px" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="body2" sx={{ fontSize: 18 }}>
                                                                        <FormattedMessage id="tools" />
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItemButton>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {manage === 1 ? <Divider sx={{ marginTop: 2 }} /> : ''} */}
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon>
                                                            <IconLogout stroke={1.5} size="30px" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2" sx={{ fontSize: 18 }}>
                                                                    <FormattedMessage id="logout" />
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </List>
                                            </Box>
                                        </PerfectScrollbar>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
