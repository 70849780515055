import dashboard from './dashboard';
import application from './application';
import forms from './forms';
import elements from './elements';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';
import menuItem1 from './menuItem1';
import menuList from './menuList';
// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    items: [menuItem1 /*  menuList dashboard, application, forms, elements, pages, utilities, support, other */]
};

export default menuItems;
