import { Grid, Typography, CircularProgress } from '@mui/material';

const Loading = () => {
    const text = 'Now loading';
    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 15 }}>
            <Grid>
                <CircularProgress size="8rem" />
            </Grid>
            <Grid>
                <Typography variant="h6" sx={{ fontSize: 16, marginTop: 5 }} color="#697586">
                    {text}
                </Typography>
            </Grid>
        </Grid>
    );
};
export default Loading;
