// action - state management
import { CHCHANGE, CHGRANT, ENDAT, LOGIN, LOGOUT, REGISTER, STARTAT, UDDISPLANG, UDLANGLIST, UDPOS, UDTOKEN, UDUSER } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    tokens: null,
    chId: null,
    admins: null,
    funcItems: null,
    langList: null,
    groupItems: null,
    startAt: null,
    endAt: null,
    lat: null,
    lng: null
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user, tokens, chId, startAt, endAt, admins, funcItems, groupItems, langList, dispLangList, lat, lng, fbHash } =
                action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user,
                tokens,
                chId,
                admins,
                funcItems,
                groupItems,
                fbHash,
                langList,
                dispLangList,
                startAt,
                endAt,
                lat,
                lng
            };
        }
        case UDUSER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case UDTOKEN: {
            const { tokens } = action.payload;
            console.log('tokenUpdated', tokens);
            return {
                ...state,
                tokens
            };
        }
        case CHCHANGE: {
            const { user, chId, admins } = action.payload;
            console.log('state', state);
            return {
                ...state,
                user,
                chId,
                admins
            };
        }
        case CHGRANT: {
            const { admins } = action.payload;
            return {
                ...state,
                admins
            };
        }

        case STARTAT: {
            const { startAt } = action.payload;
            return {
                ...state,
                startAt
            };
        }
        case ENDAT: {
            const { endAt } = action.payload;
            return {
                ...state,
                endAt
            };
        }
        case UDPOS: {
            const { lat, lng } = action.payload;
            return {
                ...state,
                lat,
                lng
            };
        }
        case UDDISPLANG: {
            const { dispLangList } = action.payload;
            return {
                ...state,
                dispLangList
            };
        }
        case UDLANGLIST: {
            const { langList } = action.payload;
            return {
                ...state,
                langList
            };
        }
        case LOGOUT: {
            return {
                initialState
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
