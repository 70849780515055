import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography, useMediaQuery } from '@mui/material';

// project imports
import NavItem from './NavItem';
import menuItem from 'menu-items';
import menuItems from 'menu-items/menuItems';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';
// import { Menu } from 'menu-items/widget';

import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import useAuth from 'hooks/useAuth';
import Loading from 'utils/Loading';
import axios from 'axios';

// ==============================|| SIDEBAR MENU LIST ||============================== //
let menus;
let dispLangList = {};
let langList = [];

const MenuList = () => {
    const theme = useTheme();
    const { user, tokens, admins, handleTokenUpdate, handleDispLangChange, handleLangListChange } = useAuth();
    const { layout } = useConfig();
    const [menu, setMenu] = useState(menuItem);
    const [isLoading, setLoading] = useState(true);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    console.log(user, dispLangList);
    const userLang = user?.userData.admin_language;
    menus = menuItem;
    const menuList = menus.items[0].children;

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
    };

    const headers1 = {
        'Content-Type': 'application / x-www-form-urlencoded'
    };
    // const getMenu = Menu();

    const menuTranslated = () => {
        const dispLangData = {
            documentName: 'dispLabels'
        };
        axios
            .post(`${process.env.REACT_APP_FINDOUT_API_URL}admin1/config/item`, dispLangData, {
                headers
            })
            .then((res) => {
                dispLangList = res.data;
                const menuL = menuList.map((el) => {
                    el.title = dispLangList[el.id]?.translated[userLang] ?? dispLangList[el.id]?.label;
                    return el;
                });
                setMenu(menus);
                setLoading(false);
            })
            .then(() => {
                handleDispLangChange(dispLangList);
            });
    };

    const getLangList = () => {
        const langData = {
            documentName: 'language'
        };
        axios
            .post(`${process.env.REACT_APP_FINDOUT_API_URL}admin1/config/item`, langData, {
                headers
            })
            .then((res) => {
                const data = res.data;
                const list = [];
                Object.keys(data).map((el) => {
                    const elm = { [`${el}`]: data[el] };
                    list.push(elm);
                    return list;
                });
                langList = list;
            })
            .then(() => {
                handleLangListChange(langList);
            });
    };

    const updateToken = () => {
        const data = {
            grant_type: 'refresh_token',
            refresh_token: tokens?.refreshToken
        };
        axios.post(`https://securetoken.googleapis.com/v1/token?key=${tokens?.key}`, data, { headers: headers1 }).then((res) => {
            const newToken = res.data.id_token;
            const newRefreshToken = res.data.refresh_token;
            handleTokenUpdate(newToken, newRefreshToken);
        });
    };

    useEffect(() => {
        setLoading(true);

        if (!user === false) {
            menuTranslated();
            getLangList();
        }

        const interval = setInterval(() => {
            updateToken();
        }, 1500000);
        return () => clearInterval(interval);
    }, [user]);

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;
    let lastItemIndex = menu.items.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < menu.items.length) {
        lastItemId = menu.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = menu.items.slice(lastItem - 1, menu.items.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }

    /* <Loading key={item.id} /> */

    const navItems = menu.items.slice(0, lastItemIndex + 1).map((item) => {
        const menuData = isLoading ? (
            ''
        ) : (
            <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />
        );
        switch (item.type) {
            case 'group':
                return menuData;

            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    /* const navItems = menuItem.items.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                if (item.url && item.id !== lastItemId) {
                    return (
                        <List key={item.id}>
                            <NavItem item={item} level={1} isParents />
                            {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && <Divider sx={{ py: 0.5 }} />}
                        </List>
                    );
                }
                return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    }); */

    return <>{navItems}</>;
};

export default memo(MenuList);
