import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Card, Grid, InputAdornment, OutlinedInput, Popper, Typography, Stack, TextField } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
import dayjs from 'dayjs';
// project imports
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX, IconCalendarTime } from '@tabler/icons-react';
import { shouldForwardProp } from '@mui/system';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ theme, start, end, startChange, endChange, popupState, userLang, dispLangList }) => (
    <Grid>
        <Grid sx={{ marginBottom: 2, marginLeft: 2 }}>
            <Typography variant="h4" color="#364152">
                {dispLangList?.period?.translated[userLang] ?? dispLangList?.period?.label}
            </Typography>
        </Grid>
        <Grid container spacing={2} justifyItems="center" alignItems="center">
            <Grid sx={{ width: 160 }} item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                        label={dispLangList?.startAt?.translated[userLang] ?? dispLangList?.startAt?.label}
                        value={dayjs(start)}
                        onChange={(newValue) => {
                            startChange(newValue);
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid sx={{ width: 160 }} item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                        label={dispLangList?.endAt?.translated[userLang] ?? dispLangList?.endAt?.label}
                        value={dayjs(end)}
                        onChange={(newValue) => {
                            endChange(newValue);
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
                        color: theme.palette.orange.dark,
                        '&:hover': {
                            background: theme.palette.orange.dark,
                            color: theme.palette.orange.light
                        }
                    }}
                    {...bindToggle(popupState)}
                >
                    <IconX stroke={1.5} size="20px" />
                </Avatar>
            </Grid>
        </Grid>
    </Grid>
);

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const theme = useTheme();
    const { user, startAt, endAt, handleStartAtChange, handleEndAtChange, dispLangList } = useAuth();
    const [start, setStart] = useState(startAt);
    const [end, setEnd] = useState(endAt);
    const userLang = user?.userData.admin_language;

    const startChange = (value) => {
        console.log(value);
        setStart(dayjs(value).format('YYYY/MM/DD HH:mm:ss'));
        handleStartAtChange(dayjs(value).format('YYYY/MM/DD HH:mm:ss'));
    };
    const endChange = (value) => {
        console.log(value);
        setEnd(dayjs(value).format('YYYY/MM/DD HH:mm:ss'));
        handleEndAtChange(dayjs(value).format('YYYY/MM/DD HH:mm:ss'));
    };

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2 }}>
                                <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                    <IconCalendarTime stroke={1.5} size="19px" />
                                </HeaderAvatarStyle>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch
                                                                theme={theme}
                                                                start={start}
                                                                end={end}
                                                                startChange={startChange}
                                                                endChange={endChange}
                                                                popupState={popupState}
                                                                dispLangList={dispLangList}
                                                                userLang={userLang}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>

            <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
                <Grid container spacing={3} justifyItems="center" alignItems="center">
                    <Grid item sx={{ marginLeft: 5 }}>
                        <Typography variant="h4" color="#364152">
                            {dispLangList?.period?.translated[userLang] ?? dispLangList?.period?.label}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                                label={dispLangList?.startAt?.translated[userLang] ?? dispLangList?.startAt?.label}
                                value={dayjs(start)}
                                onChange={(newValue) => {
                                    startChange(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                                label={dispLangList?.endAt?.translated[userLang] ?? dispLangList?.endAt?.label}
                                value={dayjs(end)}
                                onChange={(newValue) => {
                                    endChange(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SearchSection;
