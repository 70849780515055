import React, { useEffect, useState, lazy } from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Modal,
    Stack,
    Paper,
    Typography,
    TextField,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    OutlinedInput
} from '@mui/material';

import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
// assets
import { IconReplace, IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons-react';
import User1 from 'assets/images/users/user-round.svg';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ChannelChange from './ChannelChange';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    boxShadow: 24
};

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// let chData = [];

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const ChannelChangeModal = ({ user, chId, textRef, setOpen }) => {
    const { tokens } = useAuth();
    const { borderRadius } = useConfig();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);
    const [chData, setChData] = useState([]);
    const [channelChangeModal, setChannelChangeModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [alertDialog, setAlertDialog] = useState(false);
    const [successflg, setSuccessflg] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokens.token}`
    };

    const adminList = Object.keys(user.userData[0].data.admins);
    const adminList1 = user.userData[0].data.admins;

    const getData = () => {
        adminList.forEach((item) => {
            const data = {
                channelId: item
            };
            axios
                .post(`${process.env.REACT_APP_FINDOUT_API_URL}actions1/channel/item`, data, {
                    headers
                })
                .then((res) => {
                    chData.push(
                        Object.assign(res.data.itemData[0].data, {
                            id: res.data.itemData[0].id,
                            access: adminList1[item].access
                        })
                    );
                });
            return chData;
        });
        setIsLoading1(false);
    };

    const handleChannelModalOpen = () => {
        setIsLoading(true);
        getData();
        setChannelChangeModal(true);
    };
    const handleChannelModalClose = () => {
        setChData([]);
        setIsLoading(true);
        setSuccessflg(false);
        setAlertDialog(false);
        setChannelChangeModal(false);
    };

    return (
        <>
            <ListItemButton
                sx={{ borderRadius: `${borderRadius}px` }}
                selected={selectedIndex === 4}
                onClick={(e) => handleChannelModalOpen()}
            >
                <ListItemIcon>
                    <IconReplace stroke={1.5} size="30px" />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant="body2" sx={{ fontSize: 18 }}>
                            <FormattedMessage id="change-channel" />
                        </Typography>
                    }
                />
            </ListItemButton>

            <Modal
                hideBackdrop
                open={channelChangeModal}
                onClose={handleChannelModalClose}
                aria-labelledby="title"
                aria-describedby="description"
            >
                <MainCard
                    title="Channel Change"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ...style,
                        overflow: 'auto'
                    }}
                    elevation={16}
                >
                    <ChannelChange
                        user={user}
                        chId={chId}
                        textRef={textRef}
                        setOpen={setOpen}
                        chData={chData}
                        adminList={adminList}
                        setChannelChangeModal={setChannelChangeModal}
                        setChData={setChData}
                        isLoading={isLoading}
                    />
                </MainCard>
            </Modal>
        </>
    );
};

export default ChannelChangeModal;
