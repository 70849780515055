// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconBuildingStore,
    IconBell,
    IconTicket,
    IconAccessPoint,
    IconPhoto,
    IconCalendar,
    IconSend,
    IconUsers,
    IconCreditCard,
    IconPencilQuestion,
    IconTrophy,
    IconAlignBoxLeftStretch,
    IconUserCog,
    IconBrandSuperhuman,
    IconInputSearch,
    IconFilePencil,
    IconDevices,
    IconMap2,
    IconClipboardText,
    IconComponents
} from '@tabler/icons-react';
import useAuth from 'hooks/useAuth';

const icons = {
    IconDashboard,
    IconBuildingStore,
    IconBell,
    IconTicket,
    IconAccessPoint,
    IconPhoto,
    IconCalendar,
    IconSend,
    IconUsers,
    IconCreditCard,
    IconPencilQuestion,
    IconTrophy,
    IconAlignBoxLeftStretch,
    IconUserCog,
    IconBrandSuperhuman,
    IconInputSearch,
    IconFilePencil,
    IconDevices,
    IconMap2,
    IconClipboardText,
    IconComponents
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const group = {
    id: 'group',
    title: <FormattedMessage id="group" />,
    type: 'item',
    url: '/group',
    key: 'group',
    icon: icons.IconAlignBoxLeftStretch,
    breadcrumbs: false
};

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'item',
    url: '/dashboard',
    key: 'dashboard',
    icon: icons.IconDashboard,
    breadcrumbs: false
};

const providerUser = {
    id: 'providerUser',
    title: <FormattedMessage id="providerUser" />,
    type: 'item',
    url: '/provideruser',
    key: 'provideruser',
    icon: icons.IconUsers,
    breadcrumbs: false
};

const findoutUser = {
    id: 'findoutUser',
    title: <FormattedMessage id="findoutUser" />,
    type: 'item',
    url: '/findoutuser',
    key: 'findoutuser',
    icon: icons.IconUsers,
    breadcrumbs: false
};

const contents = {
    id: 'contents',
    title: <FormattedMessage id="contents" />,
    type: 'item',
    url: '/contents',
    key: 'contents',
    icon: icons.IconComponents,
    breadcrumbs: false
};

const product = {
    id: 'product',
    title: <FormattedMessage id="product" />,
    type: 'item',
    url: '/product',
    key: 'product',
    icon: icons.IconBrandSuperhuman,
    breadcrumbs: false
};

const shop = {
    id: 'place',
    title: <FormattedMessage id="shop" />,
    type: 'item',
    url: '/place',
    key: 'shop',
    icon: icons.IconBuildingStore,
    breadcrumbs: false
};

const beacon = {
    id: 'beacon',
    title: <FormattedMessage id="beacon" />,
    type: 'item',
    url: '/beacon',
    key: 'beacon',
    icon: icons.IconAccessPoint,
    breadcrumbs: false
};

const igcomments = {
    id: 'igComments',
    title: <FormattedMessage id="igcomments" />,
    type: 'item',
    url: '/comments',
    key: 'igcomments',
    icon: icons.IconClipboardText,
    breadcrumbs: false
};

const mediamanager = {
    id: 'media-manager',
    title: <FormattedMessage id="media-manager" />,
    type: 'item',
    url: '/mediamanager',
    key: 'media',
    icon: icons.IconPhoto,
    breadcrumbs: false
};

const admin = {
    id: 'admin',
    title: <FormattedMessage id="admin" />,
    type: 'item',
    url: '/admin',
    key: 'admin',
    icon: icons.IconUserCog,
    breadcrumbs: false
};

const task = {
    id: 'task',
    title: <FormattedMessage id="task" />,
    type: 'item',
    url: '/task',
    icon: icons.IconBell,
    breadcrumbs: false
};

const message = {
    id: 'message',
    title: <FormattedMessage id="message" />,
    type: 'item',
    url: '/message',
    icon: icons.IconSend,
    breadcrumbs: false
};

const survey = {
    id: 'survey',
    title: <FormattedMessage id="survey" />,
    type: 'item',
    url: '/survey',
    key: 'survey',
    icon: icons.IconPencilQuestion,
    breadcrumbs: false
};

const prize = {
    id: 'prize',
    title: <FormattedMessage id="prize" />,
    type: 'item',
    url: '/prize',
    key: 'prize',
    icon: icons.IconTrophy,
    breadcrumbs: false
};

const schedule = {
    id: 'schedule',
    title: <FormattedMessage id="schedule" />,
    type: 'item',
    url: '/schedule',
    key: 'schedule',
    icon: icons.IconCalendar,
    breadcrumbs: false
};

const keyword = {
    id: 'keyword',
    title: <FormattedMessage id="keyword" />,
    type: 'item',
    url: '/keyword',
    key: 'keyword',
    icon: icons.IconFilePencil,
    breadcrumbs: false
};
const subscription = {
    id: 'subscription',
    title: <FormattedMessage id="subscription" />,
    type: 'item',
    url: '/subscription',
    key: 'subscription',
    icon: icons.IconCreditCard,
    breadcrumbs: false
};

const searchtype = {
    id: 'searchtype',
    title: <FormattedMessage id="searchtype" />,
    type: 'item',
    url: '/searchtype',
    key: 'searchtype',
    icon: icons.IconInputSearch,
    breadcrumbs: false
};

const map = {
    id: 'map',
    title: <FormattedMessage id="map" />,
    type: 'collapse',
    key: 'map',
    icon: icons.IconMap2
};

const sensor = {
    id: 'sensor',
    title: <FormattedMessage id="sensor" />,
    type: 'item',
    url: '/sensor',
    key: 'sensor',
    icon: icons.IconDevices,
    breadcrumbs: false
};

const menuList = {
    id: 'Menu',
    icon: icons.IconDashboard,
    type: 'group',
    key: 'Menu',
    children: [group, dashboard, providerUser, findoutUser, contents, beacon, igcomments]
};

export default menuList;
