// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconBuildingStore,
    IconBell,
    IconTicket,
    IconAccessPoint,
    IconPhoto,
    IconCalendar,
    IconSend,
    IconUsers,
    IconCreditCard,
    IconPencilQuestion,
    IconTrophy,
    IconAlignBoxLeftStretch,
    IconUserCog,
    IconBrandSuperhuman,
    IconInputSearch,
    IconFilePencil,
    IconDevices,
    IconMap2
} from '@tabler/icons-react';

const icons = {
    IconDashboard,
    IconBuildingStore,
    IconBell,
    IconTicket,
    IconAccessPoint,
    IconPhoto,
    IconCalendar,
    IconSend,
    IconUsers,
    IconCreditCard,
    IconPencilQuestion,
    IconTrophy,
    IconAlignBoxLeftStretch,
    IconUserCog,
    IconBrandSuperhuman,
    IconInputSearch,
    IconFilePencil,
    IconDevices,
    IconMap2
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'Menu',
    title: <FormattedMessage id="Menu" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        /* {
            id: 'couponlist',
            title: <FormattedMessage id="couponlist" />,
            type: 'item',
            url: '/couponlist',
            icon: icons.IconTicket,
            breadcrumbs: false
        }, */
        {
            id: 'channel',
            title: <FormattedMessage id="channel" />,
            type: 'item',
            url: '/channel',
            icon: icons.IconAlignBoxLeftStretch,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: <FormattedMessage id="user" />,
            type: 'item',
            url: '/user',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'map',
            title: <FormattedMessage id="map" />,
            type: 'collapse',
            icon: icons.IconMap2,
            children: [
                {
                    id: 'shop',
                    title: <FormattedMessage id="shop" />,
                    type: 'item',
                    url: '/shop',
                    icon: icons.IconBuildingStore,
                    breadcrumbs: false
                },
                {
                    id: 'keyword',
                    title: <FormattedMessage id="keyword" />,
                    type: 'item',
                    url: '/keyword',
                    icon: icons.IconFilePencil,
                    breadcrumbs: false
                },
                {
                    id: 'searchtype',
                    title: <FormattedMessage id="searchtype" />,
                    type: 'item',
                    url: '/searchtype',
                    icon: icons.IconInputSearch,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'beacon',
            title: <FormattedMessage id="beacon" />,
            type: 'item',
            url: '/beacon',
            icon: icons.IconAccessPoint,
            breadcrumbs: false
        },
        {
            id: 'message',
            title: <FormattedMessage id="message" />,
            type: 'item',
            url: '/message',
            icon: icons.IconSend,
            breadcrumbs: false
        },
        {
            id: 'task',
            title: <FormattedMessage id="task" />,
            type: 'item',
            url: '/task',
            icon: icons.IconBell,
            breadcrumbs: false
        },
        {
            id: 'subscription',
            title: <FormattedMessage id="subscription" />,
            type: 'item',
            url: '/subscription',
            icon: icons.IconCreditCard,
            breadcrumbs: false
        },
        {
            id: 'survey',
            title: <FormattedMessage id="survey" />,
            type: 'item',
            url: '/survey',
            icon: icons.IconPencilQuestion,
            breadcrumbs: false
        },
        {
            id: 'prize',
            title: <FormattedMessage id="prize" />,
            type: 'item',
            url: '/prize',
            icon: icons.IconTrophy,
            breadcrumbs: false
        },
        {
            id: 'product',
            title: <FormattedMessage id="product" />,
            type: 'item',
            url: '/product',
            icon: icons.IconBrandSuperhuman,
            breadcrumbs: false
        },
        {
            id: 'schedule',
            title: <FormattedMessage id="schedule" />,
            type: 'item',
            url: '/schedule',
            icon: icons.IconCalendar,
            breadcrumbs: false
        },
        {
            id: 'sensor',
            title: <FormattedMessage id="sensor" />,
            type: 'item',
            url: '/sensor',
            icon: icons.IconDevices,
            breadcrumbs: false
        },
        {
            id: 'admin',
            title: <FormattedMessage id="admin" />,
            type: 'item',
            url: '/admin',
            icon: icons.IconUserCog,
            breadcrumbs: false
        }

        /* {
            id: 'media-manager',
            title: <FormattedMessage id="media-manager" />,
            type: 'item',
            url: '/mediamanager',
            icon: icons.IconPhoto,
            breadcrumbs: false
        } */
        /* {
            id: 'analytics',
            title: <FormattedMessage id="analytics" />,
            type: 'item',
            url: '/dashboard/analytics',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        } */
    ]
};

export default dashboard;
